import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Nav } from "react-bootstrap"
import aboutImg from "../assets/images/about-company.png"
import axios from "axios"
import linkedin from "../assets/images/linkedin.png"
import twitter from "../assets/images/twitter.png"
import skype from "../assets/images/skype.png"
import instagram from "../assets/images/instagram.png"
import facebook from "../assets/images/facebook.png"
import { API_ROOT } from "gatsby-env-variables"
import { Link } from "gatsby"
import right from "../assets/images/right.svg"
import ReactHtmlParser from "react-html-parser"

class aboutPage extends React.Component {
  state = {
    aboutUs: "",
    ourMission: "",
    partnersText: "",
    team: [],
    advisory: [],
    partners: [],
    pageTitle: 'About us',
    metaArr: []
  }
  componentDidMount(){
    if(window.$crisp && window.$crisp !== undefined){
      window.$crisp.push(['do', 'chat:hide']);
    }

    this.getSetting();
    this.getTeam();
    this.getAdvisory();
    this.getPartner();
    this.getSeoData();
  }
  getSetting(){
    axios.get(API_ROOT+`/api/v1/settings`)
      .then( (response) => {
        if(response && response.data && response.data.data){
          this.setState({
            aboutUs: response.data.data.about_us,
            ourMission: response.data.data.our_mission,
            partnersText: response.data.data.partners_text,
          })
        }
      })
  }
  getTeam(){
    axios.get(API_ROOT+`/api/v1/teams`)
      .then( (response) => {
        var team = [];
        var researchTeam = [];
        if(response && response.data && response.data.data){
          response.data.data.map((e) => {
            if(e.type === 'research'){
              researchTeam.push(e)
            } else {
              team.push(e)
            }
          })
          this.setState({
            team: team,
            researchTeam: researchTeam,
          })
        }
      })
  }
  getAdvisory(){
    axios.get(API_ROOT+`/api/v1/advisory-board`)
      .then( (response) => {
        if(response && response.data && response.data.data){
          this.setState({
            advisory: response.data.data
          })
        }
      })
  }
  getPartner(){
    axios.get(API_ROOT+`/api/v1/partners`)
      .then( (response) => {
        if(response && response.data && response.data.data){
          this.setState({
            partners: response.data.data
          })
        }
      })
  }
  getSeoData(){
    axios.get(API_ROOT+`/api/v1/seo/about`)
      .then( (response) => {

        var data = response?.data?.data || [];
        var metaArr = [];
        data.map((e) =>{
          if(e.type === 'title'){
            this.setState({
              pageTitle: e.value
            })
          } else {
            metaArr.push({
              name: e.type,
              content: e.value
            })
          }
        })

        this.setState({
          metaArr: metaArr
        })

      })
  }
  render() {
    return (
      <Layout pageInfo={{ pageName: "About us" }}>
        <SEO title={this.state.pageTitle}  meta = {this.state.metaArr} />
        <Container fluid className={'inline-pages'}>
          <Row className={'text-center'}>
            <Col className={'inline-pages-title'} md={{span:10, offset:1}}>
              <p>About us</p>
            </Col>
            <Col className={'inline-pages-border'} md={{span:10, offset:1}}></Col>
            <Col md={{span:10, offset:1}} className={'p0'}>
              <p className={'bread-crumb'}>
                <Link to="/" className="link-no-style">
                  &nbsp;Home &nbsp;
                </Link>
                /  About
              </p>
            </Col>
          </Row>
          <Row>
            <Col className={'inline-pages-content-box'} md={{span:10, offset:1}}>
              <img className={'fullwidth'} src={aboutImg} alt="about us"/>
              <Col md={{span:10, offset:1}}>
                <p className={'about-us-title'}>About us</p>
                <p className={'about-us-content'}>
                  {this.state.aboutUs}
                </p>
              </Col>
              <div className={'about-us-blue'}>
                <Col className={'mission-container'} md={{span:10, offset:1}}>
                  <Col md={{span:10, offset:1}}>
                    <p className={'mission-title'}>Our Mission</p>
                    <p className={'mission-content'}>
                      {this.state.ourMission}
                    </p>
                  </Col>
                </Col>
              </div>
              {/*team section*/}
              <Row>
                <Col className='fullHeight'>
                  <Row className={'d-flex justify-content-center'}>
                    <p className={'sections-title sections-title-style'}>Team</p>
                  </Row>
                  <Row>
                    <Col md={{span:12}} className={'align-middle d-flex align-items-center justify-content-center'}>
                      <Col md={12} lg={12} xl={12}>
                        <Row className={'text-center d-flex align-items-center justify-content-center'}>
                          {this.state.team && this.state.team.map((teamData, i) => (
                            <div className={'d-flex justify-content-center mr20 ml20'} key={i}>
                              <div className="overlay-container width250 ">
                                <img src={teamData.picture['250x300-fit']} alt="team" />
                                <div className="overlay pointer width250 d-flex align-items-center justify-content-center">
                                  <div className="overlay-title">
                                    <p className='mb5'>{teamData.first_name} {teamData.last_name}</p>
                                    <p className='mb5'>{teamData.position}</p>
                                    <div className={'text-center'}>
                                      {!!teamData.linkedin && (
                                        <a href={teamData.linkedin} target='_blank'><img src={linkedin} alt="" className={'width20 height20'} /></a>
                                      )}
                                      {!!teamData.twitter && (
                                        <a href={teamData.twitter} target='_blank'><img src={twitter} alt="" className={'width20 height20'} /></a>
                                      )}
                                      {!!teamData.skype && (
                                        <a href={teamData.skype} target='_blank'><img src={skype} alt="" className={'width20 height20'} /></a>
                                      )}
                                      {!!teamData.instagram && (
                                        <a href={teamData.instagram} target='_blank'><img src={instagram} alt="" className={'width20 height20'} /></a>
                                      )}
                                      {!!teamData.facebook && (
                                        <a href={teamData.instagram} target='_blank'><img src={facebook} alt="" className={'width20 height20'} /></a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/*researchTeam section*/}
              <Row className={'mt50'}>
                <Col className='fullHeight'>
                  <Row className={'d-flex justify-content-center'}>
                    <p className={'sections-title sections-title-style'}>Research Partner (HES-SO Genève)</p>
                  </Row>
                  <Row>
                    <Col md={{span:12}} className={'align-middle d-flex align-items-center justify-content-center'}>
                      <Col md={12} lg={12} xl={12}>
                        <Row className={'text-center d-flex align-items-center justify-content-center'}>
                          {this.state.researchTeam && this.state.researchTeam.map((teamData, i) => (
                            <div className={'d-flex justify-content-center mr20 ml20'} key={i}>
                              <div className="overlay-container width250 ">
                                <img src={teamData.picture['250x300-fit']} alt="team" />
                                <div className="overlay pointer width250 d-flex align-items-center justify-content-center">
                                  <div className="overlay-title">
                                    <p className='mb5'>{teamData.first_name} {teamData.last_name}</p>
                                    <p>{teamData.position}</p>
                                    <div className={'text-center'}>
                                      {!!teamData.linkedin && (
                                        <a href={teamData.linkedin} target='_blank'><img src={linkedin} alt="" className={'width20 height20'} /></a>
                                      )}
                                      {!!teamData.twitter && (
                                        <a href={teamData.twitter} target='_blank'><img src={twitter} alt="" className={'width20 height20'} /></a>
                                      )}
                                      {!!teamData.skype && (
                                        <a href={teamData.skype} target='_blank'><img src={skype} alt="" className={'width20 height20'} /></a>
                                      )}
                                      {!!teamData.instagram && (
                                        <a href={teamData.instagram} target='_blank'><img src={instagram} alt="" className={'width20 height20'} /></a>
                                      )}
                                      {!!teamData.facebook && (
                                        <a href={teamData.instagram} target='_blank'><img src={facebook} alt="" className={'width20 height20'} /></a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/*Advisory section*/}
              <Row className={'about-advisory'}>
                <Col className='fullHeight advisory-section'>
                  <Row className={'d-flex justify-content-center pt200 pb40'}>
                    <p className={'sections-title sections-title-style advisory-title'}>Advisory Board</p>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Col md={{span:10, offset:1}} lg={{span:10, offset:1}} xl={{span:10, offset:1}} >
                        <Row>
                          <Col className={'mb20 text-center'}>
                            <Row className={'d-flex justify-content-center'}>
                              {this.state.advisory && this.state.advisory.map((advisoryData, i) => (
                                <Col sm={6} md={6} lg={3} className={'advisory-card col-12'}>
                                  <img src={advisoryData.picture} alt="risklick advisory board" />
                                  <p>{advisoryData.first_name} {advisoryData.last_name}</p>
                                  <p> { ReactHtmlParser(ReactHtmlParser(advisoryData.position)) }</p>
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                  <Row className={'d-flex justify-content-center pt200'}>
                    <p className={'sections-title sections-title-style partner-title'}>Partners</p>
                  </Row>
                </Col>
              </Row>

              {/*partners section*/}
              <Row>
                <Col className='fullHeight'>
                  <Row>
                    <Col md={12}>
                      <Col md={{span:8, offset:2}} className={'partners mb50'}>
                        <p className={'partner-description'}>
                          {this.state.partnersText}
                        </p>
                        {this.state.partners && this.state.partners.map((partner, i) => (
                          <img src={partner.picture['autox150-resize']} alt="partners" key={i}/>
                        ))}
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}


export default aboutPage

